import React from 'react';

function Docs() {
  return (
    <div>
      <p>This is docs!</p>
    </div>
  );
};

export default Docs;