import React from 'react';

function Test() {
  return (
    <div>
      <p>This is test page!</p>
    </div>
  );
};

export default Test;