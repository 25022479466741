import React from 'react';

function Profile() {
  return (
    <div>
      <p>This is Profile page!</p>
    </div>
  );
};

export default Profile;